import dynamic from 'next/dynamic';
import Image from 'next/legacy/image';
import { useState } from 'react';
import { t } from '../../../generated/i18n';
import playSvg from '../../../img/play.svg';
import {
  POST_PREVIEW_IMAGE_WIDTH,
  calc16to9ImageProps,
  img16to9Loader,
} from '../../../lib/images';
import { VideoContext } from './video-context';

const DynamicYouTubePlayer = dynamic(() => import('./youtube-player'));

export const getVideoId = (videoUrl) =>
  videoUrl.replace('https://www.youtube.com/watch?v=', '');

export const VideoPreview = ({
  video,
  image,
  className = '',
  onClick = null,
  width = POST_PREVIEW_IMAGE_WIDTH,
  preload = false,
}) => {
  image = image
    ? image
    : { url: video?.thumbnail_url, dimensions: { width: 900, height: 506 } };

  calc16to9ImageProps(image, width);

  return (
    <div className='relative'>
      <Image loader={img16to9Loader} priority={preload} {...image[width]} />
      <div
        className={
          className +
          ' absolute bottom-0 left-0 right-0 top-0 flex flex-col justify-center bg-black opacity-20'
        }
      />

      <button
        className='absolute bottom-0 top-0 z-10 block w-full'
        aria-label={t('play')}
        onClick={onClick}
      >
        <img src={playSvg} alt={t('play')} className='mx-auto' />
      </button>
    </div>
  );
};

export const Video = ({
  videoUrl = null,
  video = null,
  image = null,
  className = '',
  trackingLabel = '',
  width = POST_PREVIEW_IMAGE_WIDTH,
  preload = false,
}) => {
  const [videoVisible, setVideoVisible] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    setVideoVisible(true);
  };

  if (videoUrl && !video) {
    const vid = getVideoId(videoUrl);
    video = {
      embed_url: videoUrl,
      id: vid,
      thumbnail_url: `https://img.youtube.com/vi/${vid}/maxresdefault.jpg`,
    };
  }

  return (
    <div className={className}>
      {videoVisible ? (
        <VideoContext.Provider
          value={{
            videoId: video.id ? video.id : getVideoId(video.embed_url),
            trackingLabel,
            onEnd: () => setVideoVisible(false),
          }}
        >
          <DynamicYouTubePlayer />
        </VideoContext.Provider>
      ) : (
        <VideoPreview
          image={image}
          video={video}
          onClick={onClick}
          className={className}
          width={width}
          preload={preload}
        />
      )}
    </div>
  );
};
