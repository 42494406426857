import Image from 'next/legacy/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import {
  TEASE_IMAGE_SIZE,
  calcImageProps,
  prismicLoader,
} from '../../lib/images';
import { ButtonLink } from '../common/button';
import List from '../common/list';
import { RichText } from './rich-text';

const quizReady = {
  text: '<h2>Weine die dir schmecken</h2><p>Eine Weinauswahl nur für dich.</p>',
  button_link: '/quiz',
  button_label: 'zu den Weinen',
};

const dreamWinesReady = {
  text: '<h2>Weine nach deinem Geschmack</h2><p>Diese Weine enstprechen deinem Gusto garantiert.</p>',
  button_link: '/wein?filter=traumwein--true',
  button_label: 'zu den Weinen',
};

const Teaser = ({ item, className = '' }) => {
  return (
    <Link href={item.button_link} legacyBehavior>
      <div
        className={`preview-item flex cursor-pointer flex-col rounded-lg bg-white text-center ${className}`}
      >
        {item.image && (
          <div className='teaserImage'>
            <Image
              loader={prismicLoader}
              src={item.image.url}
              {...calcImageProps(item.image, TEASE_IMAGE_SIZE)}
            />
          </div>
        )}
        {item.text && (
          <RichText
            className='cmsRichText mx-auto mt-4 max-w-lg flex-grow text-center text-base'
            elements={item.text}
          />
        )}
        {item.button_link && item.button_label ? (
          <ButtonLink className='mt-4 w-full' href={item.button_link}>
            {item.button_label}
          </ButtonLink>
        ) : (
          <></>
        )}
      </div>
    </Link>
  );
};

export const TeaserSlice = ({
  primary,
  fields,
  className = '',
  user,
  userInitialized,
}) => {
  const [localFields, setLocalFields] = useState(fields);

  useEffect(() => {
    const newFields = [...localFields];
    if (user?.dreamWines?.length) {
      newFields[0] = { ...newFields[0], ...dreamWinesReady };
    } else if (user?.metadata?.redStructure) {
      quizReady.button_link = `/wein?filter=struktur--${user.metadata.redStructure}||struktur--${user.metadata.whiteStructure}`;
      newFields[0] = { ...newFields[0], ...quizReady };
    }
    setLocalFields(newFields);
  }, [primary.personalise && userInitialized && user]);

  return <List component={Teaser} items={localFields} className={className} />;
};
