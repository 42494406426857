import Carousel from '../common/carousel';
import Grid from '../common/grid';
import List from '../common/list';

export const ProductsSlice = ({ primary, fields, className = '' }) => (
  <List
    items={fields}
    Wrapper={primary.use_grid ? Grid : Carousel}
    className={className}
    listName='cms products slice'
  />
);
