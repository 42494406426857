import { useRouter } from 'next/router';
import { ButtonLink } from '../common/button';
import { Container } from '../layout/container';
import { RichText } from './rich-text';

const wideContainer = [
  'posts',
  'products',
  'images',
  'teasers',
  'person',
  'filterwithimages',
];

const centeredContainer = ['newletter', 'kontak'];

export const SliceContainer = ({
  primary,
  blockType,
  children,
  leftAlign = true,
  rightAlign = false,
  className = '',
  Wrapper = Container,
}) => {
  // @ts-ignore
  const wrapperProps = Wrapper === 'div' ? {} : { rightAlign };
  const router = useRouter();
  return (
    <Wrapper className={className} {...wrapperProps}>
      <div
        className={`${
          !leftAlign || centeredContainer.indexOf(blockType) > -1
            ? 'mx-auto'
            : ''
        } ${wideContainer.indexOf(blockType) === -1 ? 'max-w-prose' : ''}`}
      >
        {primary.text && blockType != 'text' && (
          <RichText
            onClick={
              primary.button_link
                ? () => router.push(primary.button_link)
                : undefined
            }
            elements={primary.text}
            className={`cmsRichText-lead mx-auto mb-6 text-base ${
              primary.button_link ? 'cursor-pointer' : ''
            } ${
              centeredContainer.indexOf(blockType) > -1 || !leftAlign
                ? 'max-w-lg text-center'
                : 'text-left'
            }`}
          />
        )}

        {children}

        {primary.button_link && primary.button_label ? (
          <ButtonLink
            filled={primary.style === 'filled'}
            className='relative z-10 mx-auto mb-12 mt-12 text-center'
            href={primary.button_link}
          >
            {primary.button_label}
          </ButtonLink>
        ) : (
          <></>
        )}
      </div>
    </Wrapper>
  );
};
