import Image from 'next/legacy/image';
import { useEffect } from 'react';
import {
  calcImageProps,
  CAROUSEL_SINGLE_IMAGE_SIZE,
  prismicLoader,
} from '../../lib/images';
import { ImageCarousel } from '../common/image-carousel';
import { useLightBox } from '../lightbox-context';

export const ImageSlice = ({ fields: images }) => {
  const { setImages } = useLightBox();

  useEffect(() => {
    setImages(images);
  }, []);

  if (images.length == 1) {
    const img = images[0];
    return (
      <Image
        className='rounded-lg'
        loader={prismicLoader}
        {...calcImageProps(img, CAROUSEL_SINGLE_IMAGE_SIZE)}
      />
    );
  }

  return <ImageCarousel images={images} />;
};
