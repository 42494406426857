import Image from 'next/legacy/image';
import { useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { t } from '../../generated/i18n';
import arrowNext from '../../img/arrow-next.svg';
import {
  CAROUSEL_MULTI_IMAGE_SIZE,
  calcImageProps,
  imgixLoader,
  prismicLoader,
} from '../../lib/images';
import throttle from '../../lib/throttle';
import { useLightBox } from '../lightbox-context';

const Nav = ({ itemsRef, label, showCallback, className = '', multi = 1 }) => {
  const [show, setShow] = useState(false);
  const onItemsScroll = () => {
    setShow(showCallback(itemsRef.current));
  };

  useEffect(() => {
    const throttled = throttle(onItemsScroll, 250);
    setShow(showCallback(itemsRef.current));
    itemsRef.current.addEventListener('scroll', throttled);
    return () => window.removeEventListener('scroll', throttled);
  }, []);

  const onClick = () => {
    itemsRef.current.scroll({
      left: itemsRef.current.scrollLeft + itemsRef.current.clientWidth * multi,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {show && (
        <img
          src={arrowNext}
          alt=''
          className={className}
          aria-label={label}
          role='button'
          onClick={onClick}
        />
      )}
    </div>
  );
};

export const ImageCarousel = ({
  images,
  className = '',
  minLen = 2,
  centered = false,
  size = CAROUSEL_MULTI_IMAGE_SIZE,
}) => {
  const itemsRef = useRef();
  const { setUrl } = useLightBox();

  if (images.length < minLen) {
    return <></>;
  }

  let totalWidth = 0;

  const slides = images.map((img, k) => {
    const props = calcImageProps(img, size);
    totalWidth += props.width + 6;
    return (
      <div
        role='listitem'
        key={k}
        data-value={k}
        style={{ marginLeft: '3px', marginRight: '3px' }}
        onClick={() => setUrl(img.url)}
        className='cursor-pointer'
      >
        <Image
          className='rounded-lg'
          loader={
            img.url.indexOf('prismic.io') > -1 ? prismicLoader : imgixLoader
          }
          {...props}
        />
      </div>
    );
  });

  const backCallback = (current) => current.scrollLeft > 0;
  const forwardCallback = (current) =>
    current.scrollLeft < current.scrollWidth - current.clientWidth;

  return (
    <div className={`${className} ${centered ? 'text-center' : 'text-left'}`}>
      <ScrollContainer
        innerRef={itemsRef}
        className='scroll-container'
        style={{ overflowX: 'auto' }}
      >
        <div
          role='list'
          className='inline-flex'
          style={{ width: `${totalWidth}px` }}
        >
          {slides}
        </div>
      </ScrollContainer>
      <div className='flex justify-between'>
        <Nav
          itemsRef={itemsRef}
          label={t('back')}
          className='rotate-180 transform cursor-pointer'
          showCallback={backCallback}
          multi={-1}
        />

        <Nav
          itemsRef={itemsRef}
          label={t('forward')}
          showCallback={forwardCallback}
        />
      </div>
    </div>
  );
};
